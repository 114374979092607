import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { parse } from 'query-string';
import jwtDecode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';
import { receiveAccessToken, receiveIdToken, receiveRefreshToken } from '../../../actions/tokenActions';
import { receiveProfileData } from '../../../actions/profileActions';
import * as oidcApi from '../../../api/oidcApi';
import SalesforceApi from '../../../api/salesforceApi';
import Loader from '../../Common/Loader/Loader';
import { VIDEO_PAGE_URL, YEAR_END_STATEMENTS_URL } from '../../../url/url';
import { withLocation } from '../../../util/routerHooks';

let requested = false;

const AuthPage = ({ location, profile, receiveProfile, receiveTokens }) => {
  const redirectTo = process.env.REACT_APP_WEB_APP_BASE + location.pathname;
  if (isEmpty(profile)) {
    const search = location.search;
    const response = parse(search);
    if (requested === false) {
      requested = true;

      oidcApi.getAccessToken(response.code, redirectTo).then((response) => {
        if (response.data) {
          const idToken = response.data.id_token;
          receiveTokens(response.data.access_token, response.data.id_token, response.data.refresh_token);
          const profile = jwtDecode(idToken);
          const id = profile.params?.UniqueID;
          const roles = (profile?.params?.Roles ?? '').split(';');
          const type = roles.find((gp) => ['agent', 'player', 'financialadvisor'].includes(gp.trim().toLowerCase()))?.toLowerCase();
          SalesforceApi.getProfileInfo(id, idToken, type).then((resp) => {
            receiveProfile(resp.data);
          });
        } else if (response.error) {
          console.log(response.error);
        }
      });
    }
    return <Loader withWrapper />;
  } else {
    let to;
    if (redirectTo.includes('dashboard')) {
      to = `/dashboard/${profile.type}`;
    } else if (redirectTo.includes('statement')) {
      to = YEAR_END_STATEMENTS_URL;
    } else if (redirectTo.includes('video')) {
      to = VIDEO_PAGE_URL;
    } else {
      to = location.pathname;
    }
    return <Navigate to={to} />;
  }
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  state: state.token.state,
  nonce: state.token.nonce,
});

const mapDispatchToProps = (dispatch) => ({
  receiveProfile: (data) => dispatch(receiveProfileData(data)),
  receiveTokens: (accessToken, idToken, refreshToken) => {
    dispatch(receiveRefreshToken(refreshToken));
    dispatch(receiveAccessToken(accessToken));
    dispatch(receiveIdToken(idToken));
  },
});

export default withLocation(connect(mapStateToProps, mapDispatchToProps)(AuthPage));
